var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "container-fluid static-page-wrapper" }, [
        _c("h3", [_vm._v("運営会社")]),
        _c("div", { staticClass: "row justify-content-md-center" }, [
          _c("div", { staticClass: "components-box col-lg-6" }, [
            _c("div", { staticClass: "company-logo" }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/company-logo.svg"),
                  width: "200",
                  height: "104",
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "company-name-ja" }, [
              _c("b", [_vm._v("株式会社ロビンソン")])
            ]),
            _c("div", { staticClass: "company-name-en" }, [
              _vm._v("(英語名: Robinson Co., Ltd.)")
            ]),
            _c("div", { staticClass: "company-url" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.robinsons.co.jp/",
                    target: "_blank"
                  }
                },
                [_vm._v("https://www.robinsons.co.jp")]
              )
            ])
          ]),
          _c("div", { staticClass: "wave" }, [
            _c("img", {
              attrs: { src: require("../../assets/footer_wave.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "footer-box" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }